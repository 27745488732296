body,
html {
  background: #f3f4f5;
}
.react-datepicker {
  font-family: "GT Walsheim";
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 40px;
  padding: 5px 10px;
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 100px;
  z-index: 100;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
}
.intl-tel-input .selected-flag {
  padding: 0 15px 0 0;
}
.intl-tel-input {
  width: 100%;
  margin-top: 30px;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: transparent;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}
.intl-tel-input input,
.intl-tel-input input[type="tel"]:focus,
.intl-tel-input input[type="tel"]:active,
.intl-tel-input input[type="text"] {
  outline: none;
}
.green-button-report {
  padding: 0 15px;
  height: 26px;
  background: #99dbca;
  border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #ffffff;
  border: 1px solid #ffa300;
  font-family: "GT Walsheim";
  outline: 0 !important;
}

.educator-sessions .green-button-report {
  height: 31px;
  background: #88f6db;
  border: 1px solid #f4f4f6;
  color: #68686c;
  margin-left: auto;
}
.green-button {
  padding: 0 24px;
  height: 48px;
  background: #00bb8d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  font-family: "GT Walsheim";
  outline: 0 !important;
}
.red-button {
  padding: 0 24px;
  height: 48px;
  background: #bb003e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  font-family: "GT Walsheim";
  outline: 0 !important;
}
.dark-button {
  padding: 0 24px;
  height: 48px;
  background: #68686c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  font-family: "GT Walsheim";
  outline: 0 !important;
  position: relative;
  z-index: 1;
}
.red-button:active {
  background: #bb003e;
}
.green-button:active {
  background: #026e53;
}
.green-button:disabled {
  background: #f4f4f6;
  color: #c4c4c4;
}
.dark-button:active {
  background: #000000;
}
.orange-button {
  padding: 0 15px;
  height: 26px;
  background: #ffa300;
  border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #ffffff;
  border: 1px solid #ffa300;
  font-family: "GT Walsheim";
  outline: 0 !important;
}
.badge {
  border-radius: 50%;
  background: #ff6600;
  color: #ffffff;
  font-family: "GT Walsheim";
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup img {
  margin-bottom: 32px;
}
.popup .text {
  color: #0d0e1e;
  text-align: center;
  line-height: 175.75%;
}
.popup .text span {
  font-weight: bold;
}
.popup select {
  color: #0d0e1e;
  text-align: center;
  line-height: 175.75%;
  font-size: 16px;
}
label,
.label {
  width: 100%;
  position: relative;
  margin-bottom: 0;
}
label .error-text,
.label .error-text {
  font-size: 10px;
  text-align: initial;
  margin-bottom: 0;
  color: #bc3e06;
  position: absolute;
}
label .error-text.center,
.label .error-text.center {
  left: 0;
  right: 0;
  width: auto;
  text-align: center;
  margin-top: 5px;
}
label select,
.label select {
  margin-top: 10px;
  border-radius: 0;
  background: none;
}
input,
select {
  height: 42px;
  background: rgba(244, 244, 246, 0.2);
  border: 0;
  border-bottom: 0.5px solid #68686c;
  border-radius: 0;
  font-size: 16px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
input.input-style {
  width: 95%;
  font-size: 16px;
  color: #7f858b;
}
input:focus,
input:active,
select:focus,
select:active {
  outline: none;
}
input:disabled {
  opacity: initial;
}
input[type="tel"]:focus,
input[type="tel"]:active {
  outline: none;
}
::placeholder {
  color: #e1e1e1;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e1e1e1;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e1e1e1;
}
.text {
  font-family: "GT Walsheim";
  font-size: 14px;
  color: #68686c;
}
.text-orange {
  color: #ffa300;
}
.text-title {
  font-family: "GT Walsheim";
  font-size: 22px;
  color: #1f2023;
}
.spinner-div {
  display: flex;
  justify-content: center;
  margin: auto 0;
}
.auth-title {
  font-family: "GT Walsheim";
  font-size: 20px;
  color: #1f2023;
}
.auth-container {
  text-align: center;
  padding: 4%;
}
select.thirty {
  margin-top: 30px;
}
div.thirty {
  margin-top: 30px;
}
.signin-title-div {
  margin-top: 5%;
  margin-bottom: 5%;
}
.signin-text-div {
  padding: 0 15%;
  margin-bottom: 80px;
}
.signin-input-div {
  display: inline-block;
  width: 100%;
  margin-top: 15%;
  position: relative;
}
.signin-input-div img {
  position: absolute;
  top: 24px;
  left: 45px;
  height: 12px;
  width: auto;
}
.signin-input-div select {
  border-radius: 0;
  background: none;
}
.signin-input1 {
  width: 25%;
  margin-right: 3%;
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #68686c;
  border-radius: 0;
  background: none;
}
select.signin-input1 {
  width: 25%;
}
.signin-input2 {
  width: 70%;
  margin-left: 2%;
  padding-left: 10px;
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #68686c;
}
.signin-button-div {
  margin-top: 5%;
}
.signin-button-div .green-button {
  width: 193px;
}
.signin-reg-div {
  margin-top: 15%;
}
.auth-link-text {
  color: #ffa300;
}

.signin-email {
  padding: 0 32.2%; /* Adjust as needed */
  display: flex;
  align-items: center;
  position: relative; /* Needed for positioning */
}

.signin-email label {
  background: rgba(244, 244, 246, 0.2);
  width: auto;
  min-width: 67px;
}

.signin-email input {
  background: rgba(244, 244, 246, 0.2);
  width: 100%;
  max-width: 363px;
  height: 42px;
  margin-left: 10px; /* Add space between label and input */
}

@media (min-width: 1468px) {
  .signin-email {
    padding: 0 36%; /* Adjust as needed */
  }
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .signin-email {
    padding: 0 15%; /* Adjust as needed */
  }
}

/* Media Query for even smaller screens */
@media (max-width: 480px) {
  .signin-email {
    flex-direction: column;
    align-items: flex-start;
  }

  .signin-email label {
    margin-bottom: 5px; /* Add space between label and input on smaller screens */
  }

  .signin-email input {
    margin-left: 0;
    margin-top: 5px; /* Add space above the input on smaller screens */
  }
}

.signup-title-div {
  margin-top: 5%;
  margin-bottom: 5%;
}
.signup-input {
  margin-top: 30px;
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #68686c;
}
.signup-terms {
  margin-top: 30px;
  padding: 0 5%;
}
.signup-terms .text {
  margin-bottom: 0;
}
.signup-terms .text:last-child {
  margin-top: 20px;
}
.signup-button-div {
  margin-top: 20px;
}
.signup-button-div .green-button {
  width: 70%;
}
.sent-code-text-div {
  margin: 15% 0;
  padding: 0 10%;
}
.otp-input {
  background-color: #f4f4f6;
  border: 0;
  margin: 0 12px;
  width: 45px !important;
  height: 40px !important;
  font-size: 18px;
  font-family: "GT Walsheim";
}
.otp-container {
  justify-content: center;
}
.receiveth-not-div {
  margin-top: 20px;
}
.terms-div {
  margin-top: 30px;
}
.black {
  color: #000000;
}
.sent-code-num-div {
  margin: 20% 0 12% 0;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.edit {
  cursor: pointer;
  margin-right: 10px;
}
.sending-text {
  margin: 25% 0 15% 0;
}
.educator-login-check {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f5ecdd;
  padding: 12px;
  display: flex;
  justify-content: center;
}
.educator-login-check.checked {
  background: #00bb8d;
  color: #ffffff;
}
.educator-login-check.checked img {
  height: 20px;
  width: auto;
  position: absolute;
  left: 12px;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../src/assets/fonts/GT-Walsheim-Regular.eot");
  src: url("../src/assets/fonts/GT-Walsheim-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/GT-Walsheim-Regular.woff") format("woff"),
    url("../src/assets/fonts/GT-Walsheim-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../src/assets/fonts/GT-Walsheim-Medium.eot");
  src: url("../src/assets/fonts/GT-Walsheim-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/GT-Walsheim-Medium.woff") format("woff"),
    url("../src/assets/fonts/GT-Walsheim-Medium.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim";
  src: url("../src/assets/fonts/GT-Walsheim-Bold.eot");
  src: url("../src/assets/fonts/GT-Walsheim-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/GT-Walsheim-Bold.woff") format("woff"),
    url("../src/assets/fonts/GT-Walsheim-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
.header {
  position: relative;
  height: 60px;
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .cart {
  position: absolute;
  right: 15px;
}
.header .cart-counter {
  position: absolute;
  right: 12px;
  top: 10px;
  background: #00bb8d;
  width: 18px;
  height: 18px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 50px;
}
.header .badge {
  position: absolute;
  right: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ececec;
  color: #ffffff;
  font-family: "GT Walsheim";
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .badge img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.footer {
  position: fixed;
  z-index: 3;
  padding: 7px 0;
  bottom: -1px;
  right: 0;
  left: 0;
  display: flex;
  height: 62px;
  background: #fafafa;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
  font-size: 11px;
  color: #737373;
}
.footer .footer-nav-item {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer .footer-nav-item p {
  color: #737373;
}
.footer .footer-nav-item.active p {
  color: #ffa300;
}
.footer.educator .footer-nav-item {
  width: 50%;
}
.footer.educator .footer-nav-item.active p {
  color: #00bb8d;
}
.tabs {
  width: 100%;
}
.tabs .tab-div {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-radius: 3px;
  margin-top: 1px;
}
.tabs .tab-div .tab {
  padding: 25px 15px 16px;
  width: 100%;
  margin: 0 16px;
  cursor: pointer;
}
.tabs .tab-div .tab p {
  margin-bottom: 0;
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 14px;
  color: #737373;
  opacity: 0.7;
}
.tabs .tab-div .tab:hover {
  opacity: 0.8;
  border-bottom: 2px solid #ffa300;
}
.tabs .tab-div .tab.active {
  border-bottom: 2px solid #ffa300;
}
.tabs .tab-div .tab.active p {
  color: #ffa300;
  opacity: initial;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal .modal-main {
  position: fixed;
  background: white;
  width: 85%;
  height: auto;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
}
.modal .modal-main .close-icon {
  position: absolute;
  right: -10px;
  top: 5px;
  font-size: 24px;
  cursor: pointer;
  color: #c4c4c4;
}
.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.modal.display-block,
.spinner-modal.display-block {
  display: flex;
}
.modal.display-none,
.spinner-modal.display-none {
  display: none;
}
.cover-cart {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
}
.my-sessions .tabs {
  width: 100%;
  display: flex;
  padding: 0 16px;
  background-color: #ffffff;
}
.my-sessions .tabs .tab {
  height: 58px;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}
.my-sessions .tabs .tab p {
  margin-bottom: 0;
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 14px;
  color: #737373;
  opacity: 0.7;
}
.my-sessions .tabs .tab .tab:hover {
  opacity: 0.8;
  border-bottom: 2px solid #ffa300;
}
.my-sessions .tabs .tab.active {
  border-bottom: 2px solid #ffa300;
}
.my-sessions .tabs .tab.active p {
  color: #ffa300;
  opacity: initial;
}
.booked-sessions {
  width: 100%;
  padding: 20px 5px 100px;
  position: relative;
}
.booked-sessions .session {
  background: #ffffff;
  width: 100%;
  padding: 12px 10px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  border-top: 4px solid #ffa300;
  margin-bottom: 10px;
}
.booked-sessions .session .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.booked-sessions .session .top .text-title {
  font-size: 16px;
  margin-bottom: 0;
  color: #0d0e1e;
}
.booked-sessions .session .top .text {
  font-size: 12px;
  margin-bottom: 0;
}
.booked-sessions .session .tags {
  display: flex;
  margin-bottom: 10px;
}
.booked-sessions .session .tags .tag {
  height: 24px;
  background: #f4f4f6;
  border-radius: 50px;
  color: #68686c;
  font-size: 12px;
  padding-left: 7px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.booked-sessions .session .info {
  width: 100%;
  padding: 10px;
  background: #f4f4f6;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.booked-sessions .session .info .text {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.booked-sessions .session .info .text-title {
  font-size: 14px;
  margin-bottom: 0;
}
.booked-sessions .session .button-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.booked-sessions .session .button-div button {
  margin-bottom: 8px;
}
.booked-sessions .session .more-info {
  border-bottom: 0.5px solid #d8dde6;
}
.booked-sessions .session .more-info .text-title {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 12px;
}
.booked-sessions .session .more-info .text {
  line-height: 175.75%;
  margin-bottom: 8px;
}
.booked-sessions .session .more-info:last-child {
  border-bottom: 0;
}
.booked-sessions .session .contact {
  padding: 9px 15px;
  background: #f5f5f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.booked-sessions .session .contact .text {
  color: #ffa300;
  margin-bottom: 0;
  margin-right: 7px;
}
.booked-sessions .session .contact .orange-button {
  width: 87px;
  height: 32px;
}
.booked-sessions .no-sessions {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
}
.booked-sessions .no-sessions img {
  margin-bottom: 15px;
}
.booked-sessions .no-sessions .text {
  margin-bottom: 30px;
  text-align: center;
}
.booked-sessions .popup .qr-value {
  padding: 10px 19px;
  background: #f5f5f7;
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
  opacity: 0.7;
  margin-top: 20px;
  margin-bottom: 100px;
}
.booked-sessions .popup .qr-value .text {
  font-size: 18px;
  margin: 0;
}
.booked-sessions .popup .someone-pickup {
  background: #f5f5f7;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 118px;
}
.booked-sessions .popup .someone-pickup .text {
  color: #ffa300;
  margin-top: 20px;
  margin-bottom: 5px;
}
.booked-sessions .popup .someone-pickup .btn-div {
  display: flex;
  justify-content: center;
}
.booked-sessions .popup .someone-pickup .btn-div .orange-button {
  background: #68686c;
  border: #68686c;
  height: 33px;
  margin: 7px;
  width: 129px;
  padding: 0 10px;
}
.booked-sessions .popup .someone-pickup .btn-div .orange-button:active {
  background: #000000;
  border: #000000;
}
.booked-sessions .popup .text-copy {
  font-size: 16px;
  margin-top: 20px;
}
.booked-sessions .share-panel {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 200px;
  z-index: 1100;
  box-shadow: 2px 0px 1px 4px rgba(13, 14, 30, 0.05);
}
.booked-sessions .share-panel .close-icon {
  position: absolute;
  right: -10px;
  top: 5px;
  font-size: 24px;
  cursor: pointer;
  color: #c4c4c4;
}
.booked-sessions .share-panel .share-text {
  margin: 10px 15px;
}
.booked-sessions .share-panel .icon-div {
  padding: 25px 15px;
}
.booked-sessions .share-panel .share-icon {
  text-align: center;
}
.booked-sessions .share-panel .share-row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.booked-sessions .share-panel .share-label {
  font-size: 12px;
  margin-top: 5px;
}
.booked-sessions .close {
  color: #ff0000;
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.previous-sessions {
  width: 100%;
  padding: 20px 5px 100px;
}
.previous-sessions .sessions {
  background: #ffffff;
  width: 100%;
  padding: 12px 10px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  margin-bottom: 10px;
}
.previous-sessions .session {
  display: flex;
  justify-content: space-between;
}
.previous-sessions .session .initials-div {
  display: flex;
}
.previous-sessions .session .img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin-bottom: 8px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
}
.previous-sessions .session .initials-icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #ff6600;
  color: #fff;
  font-family: "GT Walsheim";
  font-size: 20px;
  margin-right: 10px;
}
.previous-sessions .session .text {
  color: #0d0e1e;
}
.previous-sessions .session .mini-text {
  font-family: "GT Walsheim";
  font-size: 12px;
  color: #68686c;
  margin-top: -10px;
}
.previous-sessions .session-unit {
  background: #f4f4f6;
  border-radius: 4px;
  padding: 15px 12px;
  margin-bottom: 10px;
}
.previous-sessions .session-unit .session-top {
  display: flex;
  justify-content: space-between;
}
.previous-sessions .session-unit .text {
  color: #0d0e1e;
}
.previous-sessions .session-unit .mini-text {
  font-family: "GT Walsheim";
  font-size: 12px;
  color: #68686c;
  margin-top: -10px;
}
.previous-sessions .session-unit .detail-btns {
  display: flex;
}
.previous-sessions .session-unit .detail-btns .detail-btn {
  display: flex;
  border-radius: 50px;
  border: 0.5px solid #d8d8d8;
  padding: 0 15px;
  height: 24px;
  margin-right: 10px;
}
.previous-sessions .session-unit .detail-btns .text {
  color: #68686c;
  font-size: 12px;
}
.previous-sessions .session-unit .info {
  display: flex;
  margin-top: 30px;
}
.previous-sessions .session-unit .info .date {
  margin-right: 30px;
}
.previous-sessions .session-unit .info .text {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #68686c;
}
.previous-sessions .session-unit .info .text-title {
  font-size: 14px;
  margin-bottom: 0;
  color: #68686c;
}
.previous-sessions .orange-button.grey {
  background: #f4f4f6;
  color: #68686c;
  border: 1px solid #f4f4f6;
}
.previous-sessions .no-sessions {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
}
.previous-sessions .no-sessions img {
  margin-bottom: 15px;
}
.previous-sessions .no-sessions .text {
  margin-bottom: 30px;
  text-align: center;
}
.top-panel {
  background: #fff;
  height: 58px;
  border-top: 0.5px solid #e5e5e5;
  text-align: center;
  box-shadow: 0px 2px 8px -5px rgba(0, 0, 0, 0.12);
}
.top-panel p {
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #0d0e1e;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.top-back-panel {
  background: #fff;
  height: 58px;
  border-top: 0.5px solid #e5e5e5;
  box-shadow: 0px 2px 8px -5px rgba(0, 0, 0, 0.12);
  padding-left: 20px;
  display: flex;
}
.top-back-panel .left {
  margin-top: 22px;
}
.top-back-panel p {
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #757575;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -33px;
  margin-left: 25px;
}
.back-panel {
  background: #fff;
  height: 58px;
  border-top: 0.5px solid #e5e5e5;
  box-shadow: 0px 2px 8px -5px rgba(0, 0, 0, 0.12);
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-panel span {
  height: 100%;
  display: flex;
  align-items: center;
}
.back-panel p {
  font-family: "GT Walsheim";
  font-size: 16px;
  color: #757575;
  margin-left: 25px;
  margin-bottom: 0;
}
.back-panel .logout {
  font-weight: 600;
  font-size: 14px;
  color: #ef3d4b;
}
.book-session {
  padding-bottom: 100px;
}
.book-session .book-session-body {
  padding: 20px 5px;
}
.book-session .book-session-body .list-div {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  padding: 13px;
}
.book-session .book-session-body .list-div .list-item:first-child {
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}
.book-session .book-session-body .list-div .title {
  display: flex;
}
.book-session .book-session-body .list-div .list-item {
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.book-session .book-session-body .list-div .list-item p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 8px;
  margin-left: 16px;
  color: #1d1e1c;
  opacity: 0.6;
}
.book-session .book-session-body .list-div .list-item .right {
  margin-top: 25px;
}
.book-session .book-session-body .list-div[disabled] {
  pointer-events: none;
  opacity: 0.6;
}
.book-session .popup {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.book-session .popup .title {
  text-align: center;
}
.book-session .popup .title p {
  color: #0d0e1e;
}
.book-session .popup .select-div {
  width: 100%;
}
.book-session .popup select {
  padding: 10px;
  width: 100%;
  background: 0;
  border: 0;
  border-bottom: 0.5px solid #68686c;
  outline: 0;
  margin-top: 30px;
}
.book-session .popup button {
  width: 190px;
  margin: 35px 0;
}
.book-session .errorPopup {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.book-session .errorPopup .title {
  text-align: center;
}
.book-session .errorPopup .title p {
  color: #0d0e1e;
}
.book-session .errorPopup .select-div {
  width: 100%;
}
.book-session .errorPopup select {
  padding: 10px;
  width: 100%;
  background: 0;
  border: 0;
  border-bottom: 0.5px solid #68686c;
  outline: 0;
  margin-top: 30px;
}
.book-session .errorPopup .text {
  margin-bottom: 0;
  text-align: center;
}
.book-session .errorPopup button {
  width: 190px;
  margin: 30px 0 0 0;
}
.book-session .popup-explorer {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.book-session .popup-explorer .title {
  text-align: center;
}
.book-session .popup-explorer .title p {
  color: #0d0e1e;
  line-height: 25px;
  margin-top: 40px;
}
.book-session .popup-explorer button {
  width: 100px;
  margin: 35px 0;
}
.activity-div {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  padding: 20px;
  margin-bottom: 10px;
}
.activity-div .title {
  display: flex;
  justify-content: space-between;
}
.activity-div .text {
  font-size: 14px;
  color: #1d1e1c;
  margin: 0;
}
.activity-div .small-with-opacity {
  font-size: 12px;
  color: #68686c;
  margin-top: 5px;
  opacity: 0.7;
}
.activity-div .small {
  font-size: 12px;
  color: #68686c;
  margin-top: 10px;
}
.activity-div .tag-div {
  display: flex;
  margin-top: 10px;
}
.activity-div .tag-div .right {
  margin-right: 10px;
}
.activity-div .orange-button {
  background: rgba(255, 163, 0, 0.1);
  border: rgba(255, 163, 0, 0.1);
  color: #ffa300;
}
.activity-div .down {
  margin-left: 16px;
}
.activity-div .button-close {
  background: #f4f4f6;
  border: rgba(255, 163, 0, 0.1);
  color: #68686c;
  padding: 0 15px;
  height: 26px;
  border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-family: "GT Walsheim";
  outline: 0 !important;
}
.activity-div .expanded {
  text-align: center;
  border-top: 1px solid rgba(225, 225, 225, 0.5);
  margin-top: 20px;
}
.activity-div .expanded p {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 25px;
}
.activity-div .expanded .selector {
  display: flex;
  justify-content: space-between;
}
.activity-div .expanded .selector select,
.activity-div .expanded .selector input {
  width: 100%;
  font-size: 12px;
  color: #7f858b;
}
.activity-div .expanded .addButton {
  margin-top: 35px;
}
.activity-div .expanded .addButton button {
  width: 190px;
}
.book-session-body {
  padding: 20px 5px;
}
.book-session-body .select-card {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  padding: 13px;
  margin-bottom: 15px;
  text-align: center;
}
.book-session-body .select-card .select-card-details {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.book-session-body .select-card .select-card-details .details {
  text-align: left;
  margin-bottom: -15px;
}
.book-session-body .select-card .select-card-details .details-btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.book-session-body .select-card .select-card-details .mini-text {
  font-family: "GT Walsheim";
  font-size: 10px;
  color: #68686c;
  margin-bottom: 5px;
}
.book-session-body .select-card .weekend-explorer {
  background: #fdf7ec;
  border-radius: 4px;
  padding: 8px 14px;
  text-align: left;
  margin-top: 15px;
}
.book-session-body .select-card .weekend-explorer .text {
  font-size: 12px;
  color: #ffa300;
  margin: 0px;
}
.book-session-body .select-card .weekend-explorer .text span {
  text-decoration: underline;
}
.book-session-body .hour-div {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  padding: 20px;
  margin-bottom: 10px;
}
.book-session-body .hour-div .title {
  display: flex;
  justify-content: space-between;
}
.book-session-body .hour-div .text {
  font-size: 18px;
  color: #1d1e1c;
  margin: 0;
}
.book-session-body .hour-div .orange-button {
  background: rgba(255, 163, 0, 0.1);
  border: rgba(255, 163, 0, 0.1);
  color: #ffa300;
}
.book-session-body .hour-div .down {
  margin-left: 16px;
}
.book-session-body .hour-div .button-close {
  background: #f4f4f6;
  border: rgba(255, 163, 0, 0.1);
  color: #68686c;
  padding: 0 15px;
  height: 26px;
  border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-family: "GT Walsheim";
  outline: 0 !important;
}
.book-session-body .hour-div .expanded {
  text-align: center;
  border-top: 1px solid rgba(225, 225, 225, 0.5);
  margin-top: 20px;
}
.book-session-body .hour-div .expanded p {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 25px;
}
.book-session-body .hour-div .expanded .selector {
  display: flex;
  justify-content: space-between;
}
.book-session-body .hour-div .expanded .selector select {
  width: 40%;
  font-size: 12px;
  color: #7f858b;
}
.book-session-body .hour-div .expanded .addButton {
  margin-top: 35px;
}
.book-session-body .hour-div .expanded .addButton button {
  width: 190px;
}
.popup {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup .title {
  text-align: center;
}
.popup .title p {
  color: #0d0e1e;
}
.popup .select-div {
  width: 100%;
}
.popup select {
  padding: 10px;
  width: 100%;
  background: 0;
  border: 0;
  border-bottom: 0.5px solid #68686c;
  outline: 0;
  margin-top: 30px;
}
.popup button {
  width: 190px;
  margin: 35px 0;
}
.popup-explorer {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-explorer .title {
  text-align: center;
}
.popup-explorer .title p {
  color: #0d0e1e;
  line-height: 25px;
  margin-top: 40px;
}
.popup-explorer button {
  width: 100px;
  margin: 35px 0;
}
.profile {
  padding-bottom: 65px;
  position: relative;
}
.profile .top {
  width: 100%;
  height: 85px;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.profile .top .name-badge {
  height: 44px;
  display: flex;
  align-items: center;
}
.profile .top .name-badge .badge {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ff6600;
  color: #ffffff;
  font-family: "GT Walsheim";
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.profile .top .name-badge .img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
}
.profile .top .name-badge p {
  font-size: 14px;
  color: #1f2023;
  margin-bottom: 0;
}
.profile .top .orange-button {
  height: 31px;
  background: #f4f4f6;
  border: 1px solid #f4f4f6;
  color: #68686c;
}
.profile .add-details {
  background: #fbfbfa;
  border: 0.25px solid #e1e1e1;
  position: relative;
  padding: 41px 33px 31px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}
.profile .add-details .green-button {
  position: relative;
  z-index: 1;
}
.profile .add-details .green-button img {
  margin-right: 12px;
}
.profile .add-details .text-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}
.profile .add-details .text {
  text-align: center;
  margin-bottom: 30px;
}
.profile .add-details .triangle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.profile .no-child {
  background: #ffffff;
  width: auto;
  padding: 12px 10px;
  box-shadow: 0px 0.725702px 2.90281px rgba(13, 14, 30, 0.05);
  border-radius: 3px;
  border-top: 3px solid #ffa300;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .no-child img {
  margin-bottom: 10px;
}
.profile .no-child .text-title {
  font-size: 12px;
  margin-bottom: 5px;
}
.profile .no-child .text {
  font-size: 12px;
  margin-bottom: 12px;
}
.profile .children-section {
  padding: 12px 16px;
}
.profile .children-section .membership-card {
  background: #fbfbfa;
  border: 0.159028px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0px 0.636111px 6.36111px rgba(13, 14, 30, 0.05);
  position: relative;
  padding: 15px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  overflow: hidden;
  margin-bottom: 12px;
}
.profile .children-section .membership-card .green-button {
  position: relative;
  z-index: 1;
}
.profile .children-section .membership-card .green-button img {
  margin-right: 12px;
}
.profile .children-section .membership-card .text-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 5px;
}
.profile .children-section .membership-card .text {
  text-align: center;
}
.profile .children-section .membership-card .triangle {
  position: absolute;
  left: 0;
  bottom: -50px;
}
.profile .children-section .children {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.profile .children-section .children .child-div {
  width: 50%;
  padding-bottom: 12px;
}
.profile .children-section .children .child-div .child {
  width: 100%;
  height: 160px;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.profile .children-section .children .child-div .child .img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-bottom: 8px;
  background-size: cover;
  background-position: center;
}
.profile .children-section .children .child-div .child .badge {
  height: 60px;
  width: 60px;
  background: #6c63ff;
  font-size: 22.4px;
  margin-bottom: 8px;
}
.profile .children-section .children .child-div .child .text-title {
  font-size: 14px;
  text-align: center;
  line-height: 1;
  margin-bottom: 18px;
}
.profile .children-section .children .child-div .child .text {
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}
.profile .children-section .children .child-div .child .dots {
  position: absolute;
  right: 15px;
  bottom: 28px;
  height: 14px;
  width: auto;
}
.profile .children-section .children .child-div .child .options {
  background: #ffffff;
  border: 0.267252px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 7.36534px 11.7845px rgba(13, 14, 30, 0.3);
  border-radius: 4.4192px;
  position: absolute;
  right: 10px;
  bottom: 50px;
  width: 116.37px;
}
.profile .children-section .children .child-div .child .options div {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.profile .children-section .children .child-div .child .options div .text {
  margin-bottom: 0;
}
.profile
  .children-section
  .children
  .child-div
  .child
  .options
  div:nth-child(1) {
  background: rgba(244, 244, 246, 0.3);
}
.profile .children-section .children .child-div .child.add img {
  margin-bottom: 10px;
}
.profile .children-section .children .child-div .child.add .text-title {
  margin-bottom: 5px;
}
.profile .children-section .children .child-div:nth-child(odd) {
  padding-right: 6px;
}
.profile .children-section .children .child-div:nth-child(even) {
  padding-left: 6px;
}
.profile .no-contact {
  padding: 36px 16px;
}
.profile .no-contact .contact-card {
  width: 100%;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 22px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .no-contact .contact-card img {
  margin-bottom: 10px;
}
.profile .no-contact .contact-card .text-title {
  font-size: 14px;
  text-align: center;
  line-height: 1;
  margin-bottom: 5px;
}
.profile .no-contact .contact-card .text {
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 40px;
}
.profile .no-contact .contact-card .green-button {
  width: 80%;
}
.profile .primary-contact {
  padding: 36px 5px;
}
.profile .primary-contact .contact-card {
  width: 100%;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 25px 28px 25px 11px;
  display: flex;
  position: relative;
}
.profile .primary-contact .contact-card .badge {
  width: 44px;
  height: 44px;
  background: #0070d2;
  font-family: "GT Walsheim";
  font-size: 20px;
  margin-right: 15px;
}
.profile .primary-contact .contact-card .avatar {
  width: 44px;
  height: 44px;
  margin-right: 15px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.profile .primary-contact .contact-card .text-title {
  font-size: 14px;
  margin-bottom: 3px;
}
.profile .primary-contact .contact-card .text {
  font-size: 12px;
  margin-bottom: 0;
}
.profile .primary-contact .contact-card .dots {
  margin-left: auto;
}
.profile .primary-contact .contact-card .options {
  background: #ffffff;
  border: 0.267252px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 7.36534px 11.7845px rgba(13, 14, 30, 0.3);
  border-radius: 4.4192px;
  position: absolute;
  right: 12px;
  top: 68px;
  width: 116.37px;
}
.profile .primary-contact .contact-card .options div {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.profile .primary-contact .contact-card .options div .text {
  margin-bottom: 0;
}
.profile .primary-contact .contact-card .options div:nth-child(1) {
  background: rgba(244, 244, 246, 0.3);
}
.profile .add-child {
  width: 100%;
  background: #ffffff;
  padding: 0 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  overflow: auto;
  z-index: 2;
}
.profile .add-child .line {
  width: auto;
  margin: 0 -16px;
  height: 1px;
  background: #f3f4f5;
}
.profile .add-child .text-title {
  font-size: 14px;
  text-align: center;
  color: #1f2023;
  margin-top: 18px;
}
.profile .add-child label,
.profile .add-child .label {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.profile .add-child label .text,
.profile .add-child .label .text {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
}
.profile .add-child .img-label {
  text-align: center;
}
.profile .add-child .img-label .text {
  width: 100%;
  text-align: center;
  color: #737373;
}
.profile .add-child .img-label img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile .add-child .select-label {
  display: flex;
  justify-content: space-between;
  background: rgba(244, 244, 246, 0.2);
  position: relative;
}
.profile .add-child .select-label select {
  border-radius: 0;
  width: 30%;
  height: 42px;
  padding-top: 16px;
  padding-left: 0;
  background: none;
}
.profile .add-child .select-label select:invalid {
  color: #e1e1e1;
}
.profile .add-child .select-label .error-text {
  bottom: -14px;
}
.profile .add-child input {
  height: 42px;
  padding-top: 16px;
  padding-bottom: 4px;
}
.profile .add-child .button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .add-child .button-div .green-button {
  width: 80%;
  margin-top: 30px;
}
.profile .add-child .button-div h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
}
.profile .add-contact {
  width: 100%;
  background: #ffffff;
  margin-top: 7px;
  padding: 0 16px;
  padding-bottom: 100px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
}
.profile .add-contact .text-title.edit-text {
  font-size: 14px;
  text-align: center;
  color: #1f2023;
  margin-top: 50px;
  margin-bottom: 50px;
}
.profile .add-contact label,
.profile .add-contact .label {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.profile .add-contact label .text,
.profile .add-contact .label .text {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
}
.profile .add-contact label select,
.profile .add-contact .label select {
  border-radius: 0;
  background: none;
}
.profile .add-contact .img-label {
  text-align: center;
}
.profile .add-contact .img-label .text {
  width: 100%;
  text-align: center;
  color: #737373;
}
.profile .add-contact .img-label img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile .add-contact input {
  height: 42px;
  padding-top: 18px;
  padding-bottom: 5px;
}
.profile .add-contact .button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .add-contact .button-div .green-button {
  width: 80%;
  margin-top: 30px;
}
.profile .add-contact .button-div h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
}
.profile .logout-div {
  text-align: center;
}
.profile .logout-div .green-button {
  width: 140px;
  background: #ffffff;
  color: #ef3d4b;
  margin-bottom: 20px;
  margin-top: 20px;
}
.profile .logout-div .green-button:active {
  background: #ef3d4b;
  color: #ffffff;
}
.profile .popup .text {
  font-size: 16px;
  margin-top: 20px;
}
.profile .popup .modalBtn {
  display: flex;
  justify-content: space-between;
}
.profile .popup .modalBtn .green-button {
  width: 100px;
  margin: 20px 15px 10px 15px;
}
.profile .popup .modalBtn .green-button.outline {
  border: 1px solid #ef3d4b;
  color: #ef3d4b;
  background: transparent;
}
.centers {
  padding: 20px 16px;
  padding-bottom: 100px;
  width: 100%;
}
.centers .center-card {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  margin-bottom: 25px;
}
.centers .center-card img {
  width: 100%;
  height: auto;
}
.centers .center-card .info {
  width: 100%;
  padding: 15px 17px;
}
.centers .center-card .info .text-title {
  font-size: 14px;
  margin-bottom: 5px;
}
.centers .center-card .info .text {
  margin-bottom: 0;
}
.centers .center-card .info .orange-button {
  background: #f4f4f6;
  border: 1px solid #f4f4f6;
  color: #68686c;
  margin-top: 10px;
}
.centers .center-card .info .orange {
  margin-left: 10px;
  background: #ffa300;
  color: #ffffff;
  border: 1px solid #ffa300;
}
.centers .coming-soon {
  width: 100%;
  position: relative;
}
.centers .coming-soon .overlay {
  border-radius: 4px 4px 0 0;
  background: linear-gradient(
    0deg,
    rgba(27, 34, 25, 0.63),
    rgba(27, 34, 25, 0.63)
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}
.cart .cart-empty {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart .cart-empty .text {
  font-size: 14px;
  margin-top: 20px;
  color: #737373;
  opacity: 0.7;
}
.cart .cart-empty .green-button {
  width: 190px;
  margin-top: 10px;
}
.cart .cart-not-empty {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  margin: 20px 5px;
  padding: 15px;
  position: relative;
}
.cart .cart-not-empty .name-container {
  display: flex;
}
.cart .cart-not-empty .name-container .badge {
  width: 44px;
  height: 44px;
  font-size: 20px;
  margin-right: 10px;
}
.cart .cart-not-empty .name-container .name {
  color: #0d0e1e;
}
.cart .cart-not-empty .name-container .details {
  font-size: 12px;
  opacity: 0.7;
  margin-top: -12px;
}
.cart .cart-not-empty .cart-details-duration {
  background: #f4f4f6;
  opacity: 0.7;
  border-radius: 4px;
  padding: 15px;
  padding-bottom: 0;
}
.cart .cart-not-empty .cart-details-duration .details-top {
  display: flex;
  justify-content: space-between;
}
.cart .cart-not-empty .cart-details-duration .details-top .text {
  color: #0d0e1e;
  font-weight: bold;
}
.cart .cart-not-empty .cart-details-duration .details-top .orange-button {
  background: transparent;
  border: 1px solid #d93025;
  color: #d93025;
}
.cart
  .cart-not-empty
  .cart-details-duration
  .details-top
  .orange-button:active {
  background: #d93025;
  color: #ffffff;
}
.cart .cart-not-empty .cart-details-duration .details-main {
  display: flex;
  justify-content: space-between;
}
.cart .cart-not-empty .cart-details-duration .details-main .details-row .key {
  color: #68686c;
  font-size: 10px;
  opacity: 0.7;
}
.cart .cart-not-empty .cart-details-duration .details-main .details-row .value {
  margin-top: -12px;
  color: #444242;
}
.cart .cart-not-empty .cart-details-activity {
  background: #f4f4f6;
  opacity: 0.7;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
}
.cart .cart-not-empty .cart-details-activity .details-top {
  display: flex;
  justify-content: space-between;
}
.cart .cart-not-empty .cart-details-activity .details-top .text {
  color: #0d0e1e;
  font-weight: bold;
}
.cart .cart-not-empty .cart-details-activity .details-top .duration {
  font-size: 12px;
  opacity: 0.7;
  margin-top: -10px;
}
.cart .cart-not-empty .cart-details-activity .details-top .orange-button {
  background: transparent;
  border: 1px solid #d93025;
  color: #d93025;
}
.cart
  .cart-not-empty
  .cart-details-activity
  .details-top
  .orange-button:active {
  background: #d93025;
  color: #ffffff;
}
.cart .cart-not-empty .cart-details-activity .tags {
  display: flex;
}
.cart .cart-not-empty .cart-details-activity .tags .orange-button {
  background: transparent;
  border: 0.5px solid #d8d8d8;
  color: #38383a;
  margin-right: 10px;
  opacity: 1;
}
.cart .cart-not-empty .cart-details-activity .details-main {
  display: flex;
  justify-content: space-between;
}
.cart .cart-not-empty .cart-details-activity .details-main .details-row .key {
  color: #68686c;
  font-size: 10px;
  opacity: 0.7;
  margin-top: 20px;
}
.cart .cart-not-empty .cart-details-activity .details-main .details-row .value {
  margin-top: -12px;
  color: #444242;
}
.cart .cart-full {
  margin-bottom: 320px;
}
.cart .checkout {
  position: fixed;
  background: #fafafa;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px 40px 80px 40px;
  text-align: center;
}
.cart .checkout .discount {
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}
.cart .checkout .discount .discount-div {
  display: flex;
  justify-content: space-between;
}
.cart .checkout .discount .discount-div .discount-value {
  color: #ff6600;
  font-size: 16px;
}
.cart .checkout .discount .discount-div .discount-key {
  color: #ff6600;
}
.cart .checkout .discount .discount-div .sub-value {
  color: #0d0e1e;
  font-size: 16px;
}
.cart .checkout .total {
  display: flex;
  justify-content: space-between;
}
.cart .checkout .total .key {
  color: #68686c;
}
.cart .checkout .total .value {
  color: #0d0e1e;
  font-size: 16px;
}
.cart .checkout .green-button {
  width: 100%;
}
.membership {
  position: relative;
  margin-bottom: 90px;
}
.membership .btn-div {
  width: 100%;
  position: fixed;
  text-align: center;
  padding: 10px 60px;
  bottom: 62px;
  z-index: 3;
  background: #f3f4f5;
}
.membership .btn-div .total {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}
.membership .btn-div .total .key {
  color: #68686c;
  margin-bottom: 0;
}
.membership .btn-div .total .value {
  color: #0d0e1e;
  font-size: 16px;
  margin-bottom: 0;
}
.membership .btn-div .green-button {
  width: 193px;
}
.membership .option {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  width: 100%;
  padding: 20px 14px;
  margin-bottom: 15px;
}
.membership .option .top {
  padding: 7px 0;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;
}
.membership .option .top .text,
.membership .option .top .text-title {
  margin: 0;
}
.membership .option .top .text-title {
  color: #0d0e1e;
  font-size: 20px;
}
.membership .option .benefit {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.membership .option .benefit .text {
  margin: 0;
  margin-left: 12px;
}
.membership .option .benefit h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 0;
}
.membership .option .benefit h5:active {
  opacity: 0.5;
}
.membership .option .benefit h5.disabled {
  color: #c4c4c4;
}
.membership .option .benefit h5.disabled:active {
  opacity: initial;
}
.membership .option .select-plan {
  padding-top: 20px;
}
.membership .option .select-plan h5 {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
}
.membership .option .select-plan h5:active {
  opacity: 0.5;
}
.membership .option .select-plan h5.disabled {
  color: #c4c4c4;
}
.membership .option .select-plan h5.disabled:active {
  opacity: initial;
}
.membership .option .kids-div {
  border-top: 1px solid #00000020;
  margin-top: 20px;
}
.membership .option .kids-div .membership {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.membership .option .kids-div .kids {
  border-bottom: 1px solid #00000020;
  padding-top: 15px;
}
.membership .option .kids-div .kids .kidRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.membership .option .kids-div .btnDiv {
  margin-top: 20px;
  text-align: center;
}
.membership .option .kids-div .btn {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #ffffff;
  border: 1px solid #00bb8d;
  color: #00bb8d;
}
.membership .option .kids-div .btnRemove {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #ffffff;
  border: 1px solid red;
  color: red;
  height: 30px;
}
.membership .option .kids-div .btn:active {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #00bb8d;
  border: 1px solid #00bb8d;
  color: #ffffff;
}
.membership .option.selected {
  border-top: 6px solid #00bb8d;
}
.membership .member,
.membership .package {
  padding: 20px 16px;
  padding-bottom: 150px;
}
.membership .package-name {
  text-transform: capitalize;
}
.see-more {
  margin: 20px 5px 200px 5px;
  background: #ffffff;
  padding: 10px 15px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
}
.see-more .more-unit {
  border-radius: 4px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}
.see-more .more-unit .more-top {
  display: flex;
  justify-content: space-between;
}
.see-more .more-unit .text {
  color: #0d0e1e;
}
.see-more .more-unit .mini-text {
  font-family: "GT Walsheim";
  font-size: 12px;
  color: #68686c;
  margin-top: -10px;
}
.see-more .more-unit .detail-btns {
  display: flex;
}
.see-more .more-unit .detail-btns .detail-btn {
  border-radius: 50px;
  border: 0.5px solid #d8d8d8;
  padding: 1.5px 15px;
  height: 24px;
  margin-right: 10px;
  background: #f4f4f6;
}
.see-more .more-unit .detail-btns .text {
  color: #68686c;
  font-size: 12px;
}
.see-more .more-unit .info {
  display: flex;
  margin-top: 30px;
}
.see-more .more-unit .info .date {
  margin-right: 30px;
}
.see-more .more-unit .info .text {
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #68686c;
}
.see-more .more-unit .info .text-title {
  font-size: 14px;
  margin-bottom: 0;
  color: #68686c;
}
.see-more .more-main {
  padding: 15px 0;
}
.see-more .more-main .title {
  color: #0d0e1e;
}
.see-more .more-main .vid-div {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.see-more .more-main .vid-div video {
  border-radius: 4px;
  width: 100%;
}
.see-more .more-main .more-img-div-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.5px;
}
.see-more .more-main .more-img-div-container .img-div {
  width: 33.33%;
  padding: 2.5px;
  border-radius: 4px;
}
.see-more .more-main .more-img-div-container .img-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.educator-sessions {
  padding: 20px 10px;
  padding-bottom: 100px;
}
.educator-sessions .title {
  color: #2d2e30;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}
.educator-sessions .sessions-card {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(13, 14, 30, 0.08);
  border-radius: 4px;
  padding: 23px 15px;
  margin-bottom: 15px;
}
.educator-sessions .sessions-card .top {
  display: flex;
  margin-bottom: 15px;
}
.educator-sessions .sessions-card .top .badge {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  font-size: 20px;
  background: #ff6600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.educator-sessions .sessions-card .top .name {
  color: #0d0e1e;
  font-size: 14px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .top .age {
  color: #68686c;
  font-size: 14px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .top .time-left {
  margin-left: auto;
  background: #f5ecdd;
  border-radius: 7px;
  height: 50px;
  min-width: 54px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.educator-sessions .sessions-card .top .time-left p:first-child {
  color: #ffa300;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 22px;
}
.educator-sessions .sessions-card .top .time-left p:last-child {
  color: #ffa300;
  font-size: 7px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .lesson-info {
  padding: 12px 15px;
  background: #ecedee;
  border-radius: 4px;
  margin-bottom: 20px;
}
.educator-sessions .sessions-card .lesson-info .lesson-name {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 0.7px solid rgba(225, 225, 225, 0.5);
}
.educator-sessions .sessions-card .lesson-info .lesson-name p:first-child {
  color: #0d0e1e;
  font-size: 14px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .lesson-info .lesson-name p:last-child {
  color: #959598;
  font-size: 12px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .lesson-info .lesson-time {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}
.educator-sessions .sessions-card .lesson-info .lesson-time div p:first-child {
  color: #959598;
  font-size: 12px;
  margin-bottom: 0;
}
.educator-sessions .sessions-card .lesson-info .lesson-time div p:last-child {
  color: #0d0e1e;
  font-size: 14px;
  margin-bottom: 0;
}
.educator-sessions
  .sessions-card
  .lesson-info
  .lesson-time
  div
  p:last-child
  sup {
  font-size: 8px;
  color: #0d0e1e;
}
.educator-sessions .sessions-card .session-buttons {
  display: flex;
}
.educator-sessions .sessions-card .session-buttons .green-button.end {
  width: auto;
  flex-grow: 1;
  background: #68686c;
}
.educator-sessions .sessions-card .session-buttons .green-button.end:active {
  opacity: 0.8;
}
.educator-sessions .sessions-card .session-buttons .green-button.camera {
  width: 92px;
  margin-left: 24px;
}
.educator-sessions .sessions-card .session-buttons.new {
  display: flex;
  justify-content: center;
}
.educator-sessions .sessions-card .session-buttons.new .green-button.start {
  width: 80%;
}
.educator-sessions .sessions-card .center-content {
  display: flex;
  justify-content: center;
}
.educator-sessions .sessions-card .media-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 18px;
  border-top: 0.7px solid rgba(225, 225, 225, 0.5);
  margin-top: 11px;
}
.educator-sessions .sessions-card .media-container .media {
  position: relative;
  width: 33.3%;
  height: 86px;
  background: #737373;
  border-radius: 7px;
  border: 4px solid #ffffff;
  background-position: center;
  background-size: cover;
}
.educator-sessions .sessions-card .media-container .media .video-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.educator-sessions .sessions-card .media-container .media .close-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 2;
}
.educator-sessions .sessions-card .media-container .image-container {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.educator-sessions .sessions-card .media-container .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}
.end-session-modal {
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
}
.end-session-modal .check-icon {
  margin-bottom: 15px;
}
.end-session-modal .error-text {
  color: #bc3e06;
  font-size: 10px;
  text-align: center;
  margin-top: -20px;
}
.end-session-modal h3 {
  text-align: center;
  color: #0d0e1e;
  font-size: 14px;
  margin-bottom: 45px;
}
.end-session-modal h3.small-margin {
  margin-bottom: 20px;
}
.end-session-modal h3.error {
  color: #bc3e06;
  margin-bottom: 0;
  margin-top: 15px;
}
.end-session-modal textarea {
  height: 71px;
  padding: 10px 10px 20px 10px;
  width: 100%;
  font-size: 14px;
  resize: none;
  border: none;
  border-bottom: 0.5px solid #68686c;
  margin-bottom: 45px;
}
.end-session-modal textarea:focus,
.end-session-modal textarea:active {
  outline: none;
}
.end-session-modal .button-div {
  display: flex;
  justify-content: center;
}
.end-session-modal .button-div .hollow-button {
  padding: 0 24px;
  height: 48px;
  background: transparent;
  font-size: 14px;
  color: #68686c;
  border: 1.3px solid #68686c;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  font-family: "GT Walsheim";
  outline: 0 !important;
  margin-right: 20px;
}
.end-session-modal .button-div .green-button {
  padding-left: 35px;
  padding-right: 35px;
}
.view-media img,
.view-media video {
  width: 100%;
  height: auto;
}
.educator-profile .top {
  padding: 32px 19px;
  background: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.educator-profile .top .name-badge {
  height: 44px;
  display: flex;
  align-items: center;
}
.educator-profile .top .name-badge .badge {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ff6600;
  color: #ffffff;
  font-family: "GT Walsheim";
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.educator-profile .top .name-badge .img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
}
.educator-profile .top .name-badge p {
  font-size: 14px;
  color: #1f2023;
  margin-bottom: 0;
  max-width: 122px;
}
.educator-profile .top .orange-button {
  height: 31px;
  background: #f4f4f6;
  border: 1px solid #f4f4f6;
  color: #68686c;
  margin-left: auto;
}
.educator-profile .profile-tab {
  padding: 15px 19px;
  background: #ffffff;
  margin-top: 1px;
  font-size: 14px;
  color: #00bb8d;
  font-weight: 500;
}
.educator-profile .sessions-card .top {
  padding: 0;
}
.educator-profile .sessions-card .lesson-info {
  margin-bottom: 0;
}
.educator-profile .popup .text {
  font-size: 16px;
  margin-top: 20px;
}
.educator-profile .popup .modalBtn {
  display: flex;
  justify-content: space-between;
}
.educator-profile .popup .modalBtn .green-button {
  width: 100px;
  margin: 20px 15px 10px 15px;
}
.educator-profile .popup .modalBtn .green-button.outline {
  border: 1px solid #ef3d4b;
  color: #ef3d4b;
  background: transparent;
}
.edit-educator-profile {
  background: #ffffff;
  padding: 35px 25px;
  min-height: 80vh;
  margin-top: 2px;
}
.edit-educator-profile .image-div {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.edit-educator-profile .image-div img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.edit-educator-profile label,
.edit-educator-profile .label {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.edit-educator-profile label .text,
.edit-educator-profile .label .text {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
}
.edit-educator-profile input {
  height: 42px;
  padding-top: 18px;
  padding-bottom: 5px;
}
.edit-educator-profile .button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.edit-educator-profile .button-div .green-button {
  width: 80%;
  margin-top: 30px;
}
