ul.thumbs.animated{
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid rgba(0,0,0,.8);
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgba(0,0,0,.8);
}
.carousel.carousel-slider .control-arrow:hover {
  background: none;
}
.carousel.carousel-slider .control-arrow {
  top: 35px;
  bottom: 35px;
}